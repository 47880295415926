import React, { useEffect, useState } from 'react';

import { Popover, Table, DatePicker, Button } from 'antd';

import { CheckOutlined, CloseOutlined, CalendarOutlined } from '@ant-design/icons';

import getColumnSearchProps from '../../components/getColumnSearchProps';

import getMyDate from '../../components/getMyDate';

import REQUESTS from '../../api/requests';

import DeviceManagerDrawer from '../deviceManager/components/DeviceManagerDrawer';

import MigrateDns from './components/MigrateDns';
import dns_img from "../../images/dns.png";

import styles from "./index.module.scss";

const { RangePicker } = DatePicker;

export default function PlaylistsPage() {

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    const [total, setTotal] = useState(10);

    const [limit, setLimit] = useState(10);

    const [sorter, setSorter] = useState(["id", "ASC"]);

    const [search, setSearch] = useState({});

    const [date, setDate] = useState(null);

    const [expireDate, setExpireDate] = useState(null);

    const [current, setCurrent] = useState(null);

    const [open, setOpen] = useState(false);

    const [openMigrateDrawer, setOpenMigrateDrawer] = useState(false);

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Device Id",
            dataIndex: "deviceId",
            align: "center",
            sorter: true,
            ...getColumnSearchProps()
        },
        {
            title: "Name",
            dataIndex: "name",
            align: "center",
            sorter: true,
            ...getColumnSearchProps()
        },
        {
            title: "Url",
            dataIndex: "url",
            key: 'url',
            align: "center",
            width: 200,
            ...getColumnSearchProps(),
            render: (text, record, index) => {
                return text && (
                    <Popover content={text}>
                        <a href={text} target={'_blank'}
                            style={{
                                color: "#1890ff",
                                display: "block",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                width: 200,
                            }}
                        >
                            {text}
                        </a>
                    </Popover>
                );
            }
        },
        {
            title: "Free DNS",
            dataIndex: "free_dns_id",
            align: "center",
            sorter: true,
            render: (text, record, index) => {
                return record && record.free_dns_id ?
                    <CheckOutlined style={{ color: "green" }} /> :
                    <CloseOutlined style={{ color: "red" }} />
            }
        },

        {
            title: "Protected",
            dataIndex: "pin",
            align: "center",
            sorter: true,
            render: (text, record, index) => {
                return record?.is_protected ? record.pin :
                    <CloseOutlined style={{ color: "red" }} />
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            align: "center",
            sorter: true,
            render: (text, record, index) => {
                return record?.status || "-"
            },
        },
        {
            title: "Selected",
            align: "center",
            sorter: true,
            render: (text, record) => {
                return record.is_selected ?
                    <CheckOutlined style={{ color: "green" }} /> :
                    <CloseOutlined style={{ color: "red" }} />;
            },
        },
        {
            title: "Expired date",
            dataIndex: "expired_date",
            align: "center",
            sorter: true,
            render: (text, record) => {
                return text ? getMyDate(text) : "N/A"
            },
            filterDropdown: ({ }) => (
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setExpireDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined />
            ),
        },
        {
            title: "Created Date",
            dataIndex: "createdAt",
            align: "center",
            sorter: true,
            render: (text, record, index) => {
                return text ? getMyDate(text) : "N/A"
            },
            filterDropdown: ({ }) => (
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined />
            ),
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
        setSearch(filters)
        setSorter([sorter.field ? sorter.field : "id", sorter.order == "ascend" ? "ASC" : "DESC"]);
    }

    const getPlaylists = () => {
        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sorter),
        };

        setLoading(true);


        if (search.deviceId || search.name || search.url) {
            if (search.name) {
                query.search = {
                    "name": search.name[0]
                }
            }

            if (search.url) {
                let url = encodeURIComponent(search.url[0])
                query.search = {
                    url
                }
            }

            if (search.deviceId) {
                query.search = {
                    "deviceId": search.deviceId[0]
                }
            }

            query.search = JSON.stringify(query.search)
        }

        let dataArray = [
            { date: date, betweenDate: "createdAt" },
            { date: expireDate, betweenDate: "expired_date" },
        ];

        for (let i = 0; i < dataArray.length; i++) {
            if (dataArray[i].date && dataArray[i].date[0]) {
                query.between = JSON.stringify({
                    [dataArray[i].betweenDate]: {
                        from: dataArray[i].date[0] + ' 00:00',
                        to: dataArray[i].date[1] + " 23:59"
                    },
                });
            }
        }

        REQUESTS.GET_PLAYLISTS(query,
            (data) => {
                setTotal(data.count);
                setLoading(false);
                setData(data.rows)
            }, () => {
                setLoading(false);
            })
    }

    const getDevice = (id) => {
        if (!id) return setCurrent(null);

        const query = {
            filter: JSON.stringify({
                id
            })
        }
        REQUESTS.DEVICE.GET(query, (data) => {
            setCurrent(data.rows[0])
        });
    }

    useEffect(() => {
        let timout = setTimeout(() => {
            getPlaylists()
        }, 500);

        return () => {
            clearTimeout(timout)
        }
    }, [sorter, search, currentPage, limit, date, expireDate])

    return (
        <div>
            <div className={styles['header-container']}>
                <h1 className='table-title'>Playlists</h1>
                <Button onClick={() => setOpenMigrateDrawer(true)}>
                    <img src={dns_img} alt="dns" style={{ width: 20, height: 20, marginRight: 5 }} />
                    Migrate DNS
                </Button>
            </div>
            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                }}
                rowClassName={(record, index) => styles["row"]}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            setOpen(true);
                            getDevice(record.deviceId)
                        },
                    };
                }}
                scroll={{ x: "max-content" }}
                size="small"
            />
            <DeviceManagerDrawer
                visible={open}
                onClose={() => {
                    setOpen(false);
                    setCurrent(null)
                }}
                userInfo={current}
            />
            <MigrateDns
                open={openMigrateDrawer}
                onClose={() => {
                    setOpenMigrateDrawer(false)
                }}
                getData={getPlaylists}
            />
        </div>
    )
}
